export const APP_VERSION = "1.0.0";

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN",
};

export enum STATUS_CODE {
  OK = 200,
  CREATED,
  ACCEPTED,
  NON_AUTHORITATIVE_INFO,
  NO_CONTENT,
  BAD_REQUEST = 400,
  UNAUTHORIZED,
  PAYMENT_REQUIRED,
  FORBIDDEN,
  NOT_FOUND,
  INTERNAL_ERROR = 500,
}

export enum Country {
  bg = "bg",
  bs = "bs",
  cs = "cs",
  da = "da",
  de = "de",
  en = "en",
  es = "es",
  fa = "fa",
  fi = "fi",
  fr = "fr",
  el = "el",
  hr = "hr",
  hu = "hu",
  it = "it",
  ja = "ja",
  mk = "mk",
  nl = "nl",
  no = "no",
  pl = "pl",
  pt = "pt",
  ro = "ro",
  ru = "ru",
  sk = "sk",
  sl = "sl",
  sq = "sq",
  sr = "sr",
  sv = "sv",
  tr = "tr",
}

export const ORDERS = "orders";
export const RESTAURANTS = "restaurants";
