import { createContext, useEffect, useReducer } from "react";
import { FC, ReactNode } from "react";
import { Employee, KJDRole, RestaurantRole } from "klikni-jadi-shared-stuff";
import SplashScreen from "components/SplashScreen";
import { auth } from "firebase-config";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "@firebase/auth";

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user?: Employee;
}

interface AuthContextValue extends AuthState {
  method: "FirebaseAuth";
  signInWithEmail: (email: string, password: string) => Promise<any>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: "AUTH_STATE_CHANGED";
  payload: {
    isAuthenticated: boolean;
    user?: Employee;
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "AUTH_STATE_CHANGED": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: "FirebaseAuth",
  signInWithEmail: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithEmail = (email: string, password: string): Promise<any> => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = (): Promise<void> => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        user
          .getIdTokenResult()
          .then((token) => {
            if (token.claims.roles?.["kjd"] !== "superadmin") {
              logout();
              return;
            }

            dispatch({
              type: "AUTH_STATE_CHANGED",
              payload: {
                isAuthenticated: true,
                user: {
                  id: user.uid,
                  avatar: user.photoURL || "",
                  email: user.email,
                  roles: token.claims
                    ? (token.claims?.roles as Record<
                        string,
                        KJDRole | RestaurantRole
                      >)
                    : null,
                },
              },
            });
          })
          .catch(() => {
            dispatch({
              type: "AUTH_STATE_CHANGED",
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          });
      } else {
        dispatch({
          type: "AUTH_STATE_CHANGED",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "FirebaseAuth",
        signInWithEmail,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
