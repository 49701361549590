import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithoutPayload,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";

export enum StateStatus {
  Loading,
  Error,
  Done,
}

export interface GenericState<T> {
  data: T;
  error: any;
  status: StateStatus;
}

export const createGenericSlice = <
  T,
  Reducers extends SliceCaseReducers<GenericState<T>>
>({
  name = "",
  initialState,
  reducers,
}: {
  name: string;
  initialState?: GenericState<T>;
  reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      loading(state) {
        state.status = StateStatus.Loading;
      },
      error(state: GenericState<T>, action: PayloadAction<any>) {
        state.error = action.payload;
        state.status = StateStatus.Error;
      },
      success(state: GenericState<T>, action: PayloadAction<T>) {
        state.data = action.payload;
        state.status = StateStatus.Done;
      },
      ...reducers,
    },
  });
};

export type GenericActions<T> = {
  loading?: ActionCreatorWithoutPayload<string>;
  success: ActionCreatorWithOptionalPayload<T, string>;
  error: ActionCreatorWithOptionalPayload<any, string>;
};
