import LoadingScreen from "components/LoadingScreen";
import DashboardLayout from "layout";
import React from "react";
import { Fragment, lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthGuard from "components/AuthGuard";
import GuestGuard from "components/GuestGuard";

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

export const routes: Routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("views/errors/NotFoundView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("views/auth/LoginView")),
  },
  {
    path: "/",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/account",
        component: lazy(() => import("views/account/AccountView")),
      },
      {
        exact: true,
        path: "/reports/dashboard",
        component: lazy(() => import("views/reports/DashboardView")),
      },
      {
        exact: true,
        path: "/reports",
        component: () => <Redirect to="/reports/dashboard" />,
      },
      {
        exact: true,
        path: "/orders",
        component: lazy(() => import("views/order/OrderListView")),
      },
      {
        exact: true,
        path: "/invoices",
        component: lazy(() => import("views/invoices")),
      },
      {
        exact: true,
        path: "/invoices-v2",
        component: lazy(() => import("views/invoices-v2")),
      },
      {
        exact: true,
        path: "/",
        component: () => <Redirect to="/reports/dashboard" />,
      },
    ],
  },
  {
    path: "*",
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: lazy(() => import("views/reports/DashboardView")),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];
