import {
  createGenericEntityAdapter,
  createGenericEntitySlice,
} from "generics/entitiySlice";
import { Invoice } from "klikni-jadi-shared-stuff";
import { RootState } from "store";

const entityAdapter = createGenericEntityAdapter(
  (invoice: Invoice) => invoice.id
);

export const slice = createGenericEntitySlice({
  name: "invoices",
  entityAdapter,
  initialState: entityAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {},
});

export const selector = entityAdapter.getSelectors<RootState>(
  (state) => state.invoices
);

export const reducer = slice.reducer;
