import { useEffect } from "react";
import { FC, ReactNode } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  Avatar,
  Link,
} from "@material-ui/core";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import Logo from "components/Logo";
import useAuth from "hooks/useAuth";
import NavItem from "./NavItem";
import clsx from "clsx";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        icon: DashboardIcon,
        href: "/reports/dashboard",
      },
    ],
  },
  {
    subheader: "",
    items: [
      {
        title: "Orders",
        icon: RoomServiceIcon,
        href: "/orders",
      },
      {
        title: "Invoices",
        icon: ReceiptIcon,
        href: "/invoices-v2",
      },
    ],
  },
  {
    subheader: "",
    items: [
      {
        title: "Account",
        href: "/account",
        icon: PeopleAltIcon,
      },
    ],
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 50,
    height: 50,
  },
  drawer: {
    width: 256,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: 256,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    "@media (min-width:600px)": {
      width: "67px",
    },
  },
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box textAlign="center">
          <Link
            component={RouterLink}
            to="/app/account"
            variant="h5"
            color="textPrimary"
            underline="none"
          >
            {user.firstName}
          </Link>
        </Box>

        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>

        {openMobile && (
          <>
            <Divider />
            <Box p={2}>
              <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
                <Typography variant="h6" color="textPrimary">
                  Report problem
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  naum@exelerateit.com
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          // anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openMobile,
            [classes.drawerClose]: !openMobile,
          })}
          classes={{
            paper: clsx(classes.mobileDrawer, {
              [classes.drawerOpen]: openMobile,
              [classes.drawerClose]: !openMobile,
            }),
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="permanent"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          // anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openMobile,
            [classes.drawerClose]: !openMobile,
          })}
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.drawerOpen]: openMobile,
              [classes.drawerClose]: !openMobile,
            }),
          }}
          open={openMobile}
          onClose={onMobileClose}
          variant="permanent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
