import React, { FC } from "react";
import { Router } from "react-router-dom";
import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSettings } from "hooks/useSettings";
import { createTheme } from "theme";
import { create } from "jss";
import rtl from "jss-rtl";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider } from "notistack";
import { createBrowserHistory } from "history";
import ScrollReset from "components/ScrollReset";
import GlobalStyles from "components/GlobalStyles";
import CookiesNotification from "components/CookiesNotifications";
import SettingsNotifications from "components/SettingsNotifications";
import { renderRoutes, routes } from "routes";
import { AuthProvider } from "contexts/FirebaseAuthContext";
import { db } from "firebase-config";
import store, { useDispatch } from "store";
import { firebaseConfig } from "config";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                <CookiesNotification />
                <SettingsNotifications />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
