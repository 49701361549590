import { PayloadAction } from "@reduxjs/toolkit";
import { GlobalInfo } from "../types/global";
import { createGenericSlice, GenericState } from "../generics/slice";

const initialState = {
  data: {
    orderModal: false,
    orderInputSearch: null,
  },
};

export const slice = createGenericSlice({
  name: "global",
  initialState: initialState as GenericState<GlobalInfo>,
  reducers: {
    setOrderModal(state, action: PayloadAction<boolean>) {
      state.data.orderModal = action.payload;
    },
    setOrderInputSearch(state, action: PayloadAction<number>) {
      state.data.orderInputSearch = action.payload;
    },
  },
});

export const setOrderModal = slice.actions.setOrderModal;
export const setOrderInputSearch = slice.actions.setOrderInputSearch;

export const reducer = slice.reducer;
