import { combineReducers } from "@reduxjs/toolkit";
import { reducer as notificationReducer } from "slices/notification";
import { reducer as ordersReducer } from "slices/order";
import { reducer as globalReducer } from "slices/global";
import { reducer as invoicesReducer } from "slices/invoice";

const rootReducer = combineReducers({
  notifications: notificationReducer,
  orders: ordersReducer,
  global: globalReducer,
  invoices: invoicesReducer,
});

export default rootReducer;
