import React from "react";
import type { FC } from "react";
import whiteLogo from "assets/svg/white-logo.svg";

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  return (
    <img alt="Logo" src={whiteLogo} style={{ height: "30px" }} {...props} />
  );
};

export default Logo;
