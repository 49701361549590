import { AppThunk } from "store";
import { Notification } from "klikni-jadi-shared-stuff";
import { createGenericSlice, GenericState } from "generics/slice";

const initialState = {
  data: [],
};

const slice = createGenericSlice({
  name: "notifications",
  initialState: initialState as GenericState<Notification[]>,
  reducers: {},
});

export const reducer = slice.reducer;

export const getNotifications = (): AppThunk => async (dispatch) => {
  // const response = await axios.get<{ notifications: Notification[] }>(
  //   "/api/notifications"
  // );

  dispatch(slice.actions.success([]));
};

export default slice;
